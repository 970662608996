import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import Api from './api/index'
// import GSignInButton from "vue-google-signin-button"
// Vue.use(GSignInButton);
Vue.config.productionTip = false
Vue.prototype.$axios = Api;
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VDistpicker from 'v-distpicker'
export default { components: { VDistpicker }}

Vue.use(ElementUI)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')


