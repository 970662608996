<template>
  <div v-show="showLogin" class="overlay">
    <div class="loginMainWrapper">
      <div class="nolofin" @click="clicknoLogin">{{ $t("skip") }}</div>
      <div class="leftWrapper">
        <img :src="path" alt="link" class="leftPic" />
      </div>
      <div class="rightWrapper">
        <div class="forgetbox" v-if="indexNum == 2">
          <div class="backlogin" @click="backlogin">
            <img class="backimg" src="@/assets/img/login/Slice46.png" />
            <div>{{ $t("returnLogin") }}</div>
          </div>
          <div class="forgetTitle">{{$t("forget_word")}}</div>
          <div class="forgotBox">
            <div class="step">
              <div class="icon icon1" :class="step == 1 ? 'on' : ''"></div>
              <p :class="step == 1 ? 'on' : ''">{{ $t("lost_resetting") }}</p>
            </div>
             <div class="tipLine"></div>
            <div class="step">
              <div class="icon icon2" :class="step == 2 ? 'on' : ''"></div>
              <p :class="step == 2 ? 'on' : ''">{{ $t("forget_Code") }}</p>
            </div>
            <div class="tipLine"></div>
            <div class="step">
              <div class="icon icon3" :class="step == 3 ? 'on' : ''"></div>
              <p :class="step == 3 ? 'on' : ''">{{ $t("update_passwords") }}</p>
            </div>
          </div>
          <div class="inputbox">
            <div class="formBox" v-if="step==1">
              <label for="email">{{ $t("email") }}</label>
              <el-input
                type="text"
                v-model="email"
                :placeholder="$t('enter_email')"
                autocomplete="off"
                class="inputName"
              />
            </div>
            <div class="formBox" v-if="step==2">
              <label for="code">{{ $t("forget_Code") }}</label>
              <div class="getcodebox">
                <el-input
                  type="text"
                  v-model="code"
                  :placeholder="$t('update_code')"
                  autocomplete="off"
                  class="inputName"
                />
                <div class="getcode" @click="getVeriCode" v-if="successTip">
                  {{ $t("register") }}
                </div>
                <div class="getcode" v-else>{{ registerCode }}</div>
              </div>
              <div class="error">
              <p v-show="errorVisibilty">{{ errorText }}</p>
              </div>
            </div>
            <div class="formBox" v-if="step==3">
              <label for="password">{{ $t("login_password") }}</label>
              <form>
              <el-input
                type="password"
                v-model="password1"
                :placeholder="$t('login_password1')"
                autocomplete="off"
                class="inputName"
              />
            </form>
            </div>
            <div class="formBox" v-if="step==3">
              <label for="password">{{ $t("con_password") }}</label>
              <el-input
                type="password"
                v-model="password2"
                :placeholder="$t('con_password')"
                autocomplete="off"
                class="inputName"
              />
              <div class="error">
              <p v-show="errorVisibilty">{{ errorText }}</p>
              </div>
            </div>
          </div>
          <div class="sendButten" @click="forgetStep(step)">
            {{sendConent}}
          </div>
        </div>
        <div class="exchangebox" v-else>
          <div
            class="NumberBox"
            style="margin-right: 32px"
            @click="typeLogin('0')"
          >
            <div class="exchangNum" :class="indexNum == '0' ? 'on' : ''">
              {{ $t("log_in") }}
            </div>
            <div :class="indexNum == '0' ? 'line' : ''"></div>
          </div>
          <div class="NumberBox" @click="typeLogin('1')">
            <div class="exchangNum" :class="indexNum == '1' ? 'on' : ''">
              {{ $t("Sign_Up") }}
            </div>
            <div :class="indexNum == '1' ? 'line' : ''"></div>
          </div>
        </div>
        <div class="compWrapper">
          <div class="loginWin" v-if="indexNum == '0'">
            <div class="error">
              <img src="@/assets/img/login/cross.png" v-show="errorVisibilty" />
              <p v-show="errorVisibilty">{{ errorText }}</p>
            </div>
            <el-input
              autocomplete="off"
              v-model="userName"
              :placeholder="$t('enter_email')"
              class="userName"
            ></el-input>
            <div class="PwBox">
              <form>
              <el-input
                :type="ifeye ? 'password' : 'text'"
                :placeholder="$t('enter_password')"
                v-model="password"
                autocomplete="off"
                />
              </form>
              <span
                class="eye"
                :class="ifeye ? 'on' : ''"
                @click="ifeye = !ifeye"
              ></span>
            </div>
            <div class="forgetButton">
              <p @click="forgetButton">{{ $t("forget_word") }}</p>
            </div>
            <div class="loginButton" @click="Login">{{ $t("log_in") }}</div>
            <div class="terms">
              <div @click="ifagree = !ifagree">
                <img src="@/assets/img/login/agree.png" alt="" v-if="ifagree" />
                <img src="@/assets/img/login/zhen.png" alt="" v-else />
              </div>
              <p>
                {{ $t("agreement")
                }}<a href="/privacy" target="_blank"
                  >AOSEED{{ $t("privacy") }}</a>
              </p>
            </div>
          </div>
          <div class="registerWrapper" v-if="indexNum == '1'">
            <div class="formBox">
              <label for="email">{{ $t("email") }}</label>
              <el-input
                type="text"
                v-model="email"
                :placeholder="$t('enter_email')"
                autocomplete="off"
                class="inputName"
              />
            </div>
            <div class="formBox">
              <label for="code">{{ $t("forget_Code") }}</label>
              <div class="getcodebox">
                <el-input
                  type="text"
                  v-model="code"
                  :placeholder="$t('update_code')"
                  autocomplete="off"
                  class="inputName"
                />
                <div class="getcode" @click="getVeriCode" v-if="successTip">
                  {{ $t("register") }}
                </div>
                <div class="getcode" v-else>{{ registerCode }}</div>
              </div>
            </div>
            <div class="formBox">
              <label for="password">{{ $t("login_password") }}</label>
              <el-input
                type="text"
                v-model="password1"
                :placeholder="$t('login_password1')"
                autocomplete="off"
                class="inputName"
              />
            </div>
            <div class="formBox">
              <label for="password">{{ $t("con_password") }}</label>
              <el-input
                type="text"
                v-model="password2"
                :placeholder="$t('con_password')"
                autocomplete="off"
                class="inputName"
              />
            </div>
            <div class="registerbox">
              <div
                class="registerButton tip"
                v-if="
                  email == '' ||
                  code == '' ||
                  password1 == '' ||
                  password2 == ''
                "
              >
                {{ $t("Sign_Up") }}
              </div>
              <div class="registerButton" @click="registerApp" v-else>
                {{ $t("Sign_Up") }}
              </div>
            </div>
            <div class="terms">
              <div @click="ifagrees = !ifagrees">
                <img
                  src="@/assets/img/login/agree.png"
                  alt=""
                  v-if="ifagrees"
                />
                <img src="@/assets/img/login/zhen.png" alt="" v-else />
              </div>
              <p>
                {{ $t("agreement") }}
                <a href="/term" target="_blank">
                    <span style="color: #ff8100">{{ $t("res_terms") }}</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="showtip" v-if="showtip">
      <div>{{ backTips }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ifRegister: true,
      ifeye: true,
      showtip: false,
      path: require("@/assets/img/login/Slice30.png"),
      indexNum: "0",
      userName: "",
      password: "",
      errorVisibilty: false,
      errorText: "",
      ifagree: false,
      ifagrees: false,
      registerCode: "",
      successTip: true,
      email: "",
      code: "",
      backTips: "",
      password1: "",
      password2: "",
      step:1,
      sendConent:this.$t("sendEmail")
    };
  },
  computed: {
    showLogin() {
      return this.$store.state.loginshow;
    },
  },
  methods: {
    backlogin(){
      this.indexNum = 0;
      this.step = 1;
    },
    forgetButton(){
      this.indexNum = 2;
      this.sendConent= this.$t("sendEmail")
    },
    //提示
    iftip(val) {
      this.backTips = val;
      this.showtip = true;
      setTimeout(() => {
        this.showtip = false;
      }, 3000);
    },
    ifShowErrcode(errCode){
        this.errorText =errCode
        this.errorVisibilty = true;
        setTimeout(() => {
          this.errorVisibilty = false;
        }, 3000);
    },
    clicknoLogin(){
      this.$store.commit("set_global", { loginshow: false });
    },
    typeLogin(val) {
      this.indexNum = val;
    },
    //登录信息
    Login() {
      if (!this.userName || !this.password) {
        var errCode = this.$t("errTip");
        this.ifShowErrcode(errCode)
        return;
      }
      if (!this.ifagree) {
        this.iftip(this.$t("noageement"));
        return;
      }
      var data = {
        token: "123456",
        timestamp: new Date().getTime(),
        data: {
          isTemp: 0,
          loginType: 0,
          name: this.userName,
          password: this.password,
        },
      };
      this.$axios.getlogin(JSON.stringify(data)).then((res) => {
        if (res.errCode == 0) {
          this.$store.commit("set_userInfo", res.data);
          localStorage.setItem("aoseed_info", JSON.stringify(res.data));
          localStorage.setItem("userName", this.userName);
          localStorage.setItem("password", this.password);
          this.iftip(res.errMsg);
          this.ifagree = false;
          this.$store.commit("set_global", { loginshow: false });
          window.location.reload();
        } else {
          this.iftip(res.errMsg);
        }
      });
    },
    //获取验证码
    getVeriCode() {
      const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/;
      if (!reg.test(this.email)) {
        var val = this.$t("emailerror");
        this.iftip(val);
        return;
      }
      var data = {
        timestamp: new Date().getTime(),
        data: this.email,
      };
      this.$axios
        .getretrievePassword(JSON.stringify(data))
        .then((res) => {
          if (res.errCode == 0) {
            this.successTip = false;
            this.iftip(this.$t("success_code"));
            var codeTime = 60;
            var timer = setInterval(() => {
              if (codeTime > 0 && codeTime <= 60) {
                this.registerCode = codeTime-- + "S";
              } else {
                this.successTip = true;
                clearInterval(timer);
              }
            }, 1000);
          } else {
            this.iftip(res.errMsg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //注册
    registerApp() {
      if (this.password1.length < 6 || this.password1.length > 12) {
        this.iftip(this.$t("word_length"));
        return;
      }
      if (this.password1 != this.password2) {
        this.iftip(this.$t("differTwice"));
        return;
      }
      if (!this.ifagrees) {
        this.iftip(this.$t("noageement"));
        return;
      }
      var data = {
        timestamp: new Date().getTime(),
        data: {
          name: "",
          email: this.email,
          password: this.password1,
          repassword: this.password2,
          veriCode: this.code,
        },
      };
      this.$axios.getRegister(JSON.stringify(data)).then((res) => {
        //使用箭头函数就避免了this指向问题
        if (res.errCode == 0) {
          this.iftip(this.$t("register_successly"));
          this.indexNum = "0";
          this.ifagrees = false;
          this.email="";
          this.code="";
          this.password1="";
          this.password2="";
        } else {
          this.iftip(res.errMsg);
        }
      });
    },
    //验证code
    checkCode(){
        var data = {
            "timestamp": new Date().getTime(),
            "data": {
                "emailAddress": this.email,
                "veriCode": this.code
            }
        }
        this.$axios.checkVeriCode(JSON.stringify(data)).then((res)=>{
            if(res.errCode==0){
                this.step = 3
            }else{
                this.iftip(res.errMsg)
            }
        })
    },
    //忘记密码
    forgetStep(val){
        if(val==1){
           const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/;
           if (!reg.test(this.email)) {
              var val = this.$t("emailerror");
              this.iftip(val);
               return;
            }
         var data = {
        timestamp: new Date().getTime(),
        data: this.email,
        };
        this.$axios.getExchangeCode(JSON.stringify(data))
        .then((res) => {
          if (res.errCode == 0) {
            this.errorVisibilty = true
            this.successTip = false;
            this.iftip(this.$t("errEmail"));
            // this.errorText = this.$t("errEmail")
            this.step = val + 1
            var codeTime = 60;
            var timer = setInterval(() => {
              if (codeTime > 0 && codeTime <= 60) {
                this.registerCode = codeTime-- + "S";
              } else {
                this.successTip = true;
                this.errorVisibilty = false
                clearInterval(timer);
              }
            }, 1000);
            this.sendConent = this.$t("lost_next")
          } else {
            this.iftip(this.$t(res.errMsg));
          }
        })
        .catch((err) => {
          console.log(err);
        });
        } else if(val==2){
            if(this.code){
                this.checkCode()
            } else {
                this.iftip(this.$t('email_code'))
            }
        } else if(val==3){
            if (this.password1.length < 6 || this.password1.length > 12) {
                 this.iftip(this.$t("word_length"));
               return;
             }
             if (this.password1 != this.password2) {
               this.iftip(this.$t("differTwice"));
               return;
              }
        var data = {
            "timestamp": new Date().getTime(),
            "data": {
                "emailAddress": this.email,
                "password": this.password1,
                "veriCode":this.code,
            }
        }
        this.$axios.changePassword(JSON.stringify(data)).then((res)=>{
            if (res.errCode == 0) {
                this.iftip(this.$t("mod_succ"))   
                this.email="";
                this.code="";
                this.password1="";
                this.password2=""; 
                this.step= 1;
                this.indexNum ='0'  
                this.errorVisibilty = false     
            } else {
               this.iftip(this.$t("sorry"))
           }
           }).catch(err=>{
             console.log(err)
           })
        }

    }
  },
};
</script>
<style lang="scss" scoped>
.overlay {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 2001;
  width: 100vw;
  top: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  .loginMainWrapper {
    position: relative;
    width: 1015px;
    height: 670px;
    background: #ffffff;
    border-radius: 24px 24px 24px 24px;
    display: flex;
    .nolofin{
      position: absolute;
    top: 18px;
    right: 30px;
    z-index: 1;
    font-size: 16px;
    font-weight: 400;
    color: #706f6d;
    line-height: 1rem;
    cursor: pointer;
    }
    .rightWrapper {
      position: relative;
      padding: 46px 82px 0;
      width: 100%;
      .exchangebox {
        display: flex;
        align-items: flex-end;
        border-bottom-color: #d9d9d9;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        .NumberBox {
          position: relative;
          cursor: pointer;
        }
        .exchangNum {
          font-size: 20px;
          font-weight: 500;
          color: #706f6d;
          line-height: 20px;
          padding: 0 4px 10px;
        }
        .line {
          width: 100%;
          height: 4px;
          background: #ff8100;
          border-radius: 2px;
          position: absolute;
          bottom: -2px;
        }
        .on {
          font-size: 26px;
          font-weight: bold;
          color: #ff8100;
          line-height: 26px;
        }
      }
      .loginWin {
        padding: 0 24px;
        ::v-deep .el-input__inner {
          height: 54px;
          border-radius: 27px 27px 27px 27px;
          border: 1px solid #b1b0af;
        }
        .PwBox {
          position: relative;
          .eye {
            position: absolute;
            top: 18px;
            right: 20px;
            display: inline-block;
            cursor: pointer;
            width: 1.25rem;
            background-image: url("@/assets/img/login/Slice23.png");
            height: 1.25rem;
            background-repeat: no-repeat;
            background-size: cover;
          }
          .on {
            background-image: url("@/assets/img/login/Slice21.png");
          }
        }
        .userName {
          margin: 0 0 24px;
        }
        .forgetButton {
          display: flex;
          justify-content: flex-end;
          p {
            font-size: 14px;
            font-weight: 500;
            color: #3b8dfe;
            line-height: 16px;
            cursor: pointer;
            margin: 8px 0 26px;
          }
        }
        .loginButton {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 52px;
          background: #ff8100;
          border-radius: 55px 55px 55px 55px;
          cursor: pointer;
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
        }
        .terms {
          display: flex;
          position: absolute;
          bottom: 36px;
          left: 50%;
          transform: translateX(-50%);
          img {
            width: 18px;
            height: 18px;
            cursor: pointer;
          }
          p {
            margin: 0 0 0 5px;
            font-size: 12px;
            font-weight: 400;
            color: #706f6d;
            a {
              color: #ff8100;
              text-decoration: none;
            }
          }
        }
      }
      .registerWrapper {
        margin-top: 52px;
        .formBox {
          margin-bottom: 20px;
          label {
            display: flex;
          }
          ::v-deep .el-input__inner {
            border: 0 solid #dcdbda;
          }
          .inputName {
            border-bottom: 0.0625rem solid #dcdbda;
          }
          .getcodebox {
            display: flex;
            align-items: center;
            .getcode {
                min-width: 116px;
              padding: 0 10px;
              height: 26px;
              background: #ff8100;
              border-radius: 16px 16px 16px 16px;
              font-size: 12px;
              font-weight: 400;
              color: #ffffff;
              line-height: 26px;
              cursor: pointer;
              white-space: nowrap;
              text-align: center;
            }
          }
        }
        .tip {
          background: rgb(255 129 0 / 80%) !important;
        }
        .registerbox {
          display: flex;
          justify-content: center;
          align-items: center;
          .registerButton {
            width: 296px;
            height: 46px;
            background: #ff8100;
            border-radius: 23px 23px 23px 23px;
            font-size: 18px;
            font-weight: 400;
            color: #ffffff;
            line-height: 46px;
            cursor: pointer;
            margin-top: 58px;
          }
        }
        .terms {
          display: flex;
          position: absolute;
          bottom: 36px;
          left: 50%;
          transform: translateX(-50%);
          img {
            width: 18px;
            height: 18px;
            cursor: pointer;
          }
          p {
            margin: 0 0 0 5px;
            font-size: 12px;
            font-weight: 400;
            color: #706f6d;
            a {
              color: #3c3c3c;
              text-decoration: none;
            }
          }
        }
      }
      .forgetbox {
        .backlogin {
          display: flex;
          align-items: center;
          margin-top: 26px;
          cursor: pointer;
          font-size: 14px;
          font-weight: 400;
          color: #3b8dfe;
          margin-left: -24px;
          .backimg {
            width: 19px;
            height: 19px;
          }
        }
        .forgetTitle{
          padding: 42px 0 56px;
          font-size: 22px;
          font-weight: 500;
          color: #1A1816;
        }
        .forgotBox{
            position: relative;
     display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    .tipLine{
        width: 56px;
height: 0px;
opacity: 1;
border: 1px solid;
border-image: linear-gradient(63deg, rgba(255, 249, 242, 1), rgba(255, 129, 0, 1)) 1 1;
margin-top: -48px;
    }
     .thread{
      width: 57px;
      background-image: linear-gradient(63deg, rgba(255, 249, 242, 1), rgba(255, 129, 0, 1)) !important;
      margin-top: -16px;
    }
     .step{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
       .icon{
          background-repeat: no-repeat;
          background-size:cover;
          background-position: center center;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          &.icon1{
            background-image:url("@/assets/img/login/Slice24.png") ;
            &.on{
              background-image:url("@/assets/img/login/Slice27.png") !important ;
            }
          }
          &.icon2{
            background-image:url("@/assets/img/login/Slice25.png") ;
            &.on{
              background-image:url("@/assets/img/login/Slice28.png") !important ;
            }
          }
          &.icon3{
            background-image:url("@/assets/img/login/Slice26.png") ;
            &.on{
              background-image:url("@/assets/img/login/Slice29.png") !important ;
            }
          }
       }
       &>p{
        font-size: 12px;
        font-weight: 400;
        color: #706F6D;
        line-height: 18px;
        margin-top: 16px;      
        white-space: nowrap;
        &.on{
          color: #FF8100 !important;
        }
       }
     }
        }
        .inputbox{
            text-align: left;
            margin-top:28px;
            ::v-deep .el-input__inner {
            border: 0 solid #dcdbda;
          }
          .inputName {
            border-bottom: 0.0625rem solid #dcdbda;
          }
        }
        .sendButten{
            cursor: pointer;
           width: 297px;
           height: 46px;
           background: #FF8100;
           border-radius: 53px 53px 53px 53px;
           line-height: 46px;
           font-size: 18px;
           font-weight: 500;
           color: #FFFFFF;
           position: absolute;
            bottom: 80px;
           left: 50%;
            transform: translateX(-50%)
        }
      }
      .formBox {
          margin-bottom: 20px;
          label {
            display: flex;
          }
          ::v-deep .el-input__inner {
            border: 0 solid #dcdbda;
          }
          .inputName {
            border-bottom: 0.0625rem solid #dcdbda;
          }
          .getcodebox {
            display: flex;
            align-items: center;
            .getcode {
              min-width: 116px;
              padding: 0 10px;
              height: 26px;
              background: #ff8100;
              border-radius: 16px 16px 16px 16px;
              font-size: 12px;
              font-weight: 400;
              color: #ffffff;
              line-height: 26px;
              cursor: pointer;
              white-space: nowrap;
              text-align: center;
            }
          }
        }
        .error {
          display: flex;
          align-items: center;
          margin: 34px 0 6px;
          height: 16px;
          & > p {
            font-size: 16px;
            color: #ff4c4c;
            margin-left: 4px;
            margin-bottom: 4px;
          }
        }
    }
  }
  .showtip {
    position: fixed;
    top: 50%;
    left: 50%;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px 70px;
    border-radius: 20px;
    color: #ffffff;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 1200px) {
  .loginMainWrapper{
    width: 100% !important;
    margin: 30px !important;
    .leftWrapper{
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .loginMainWrapper{
    height: 520px !important;
  }
  .leftWrapper{
    display: none;
  }
  .rightWrapper{
    padding: 46px 15px 0 !important;
  }
}
</style>
