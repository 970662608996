import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: { //this.$store.state.loginStatus 这样获取
    userInfo: {
      id:-1,
      awUserAttr1:{
        money:"0",
      },
      name:"aoseed"
    },
    url:"",
    loginStatus:"",//登录状态，1为未登录，0为登录
    link:"https://en-ime3d-res.s3.us-west-1.amazonaws.com",
    link2:"https://res.ime3d.com/",
    link3:"https://x-maker-res2.s3.amazonaws.com/",
    link4:"https://www.ime3d.com",
    loginshow:false,//登录窗口显示
  },
  getters: {
  },
  mutations: {//同步执行
    set_userInfo(state, userInfo) {
      //设置用户信息-遍历对象填充
      for (var i in userInfo) {
          state.userInfo[i] = userInfo[i];
      }
  },
  set_global(state, globalInfo) {
    for (var i in globalInfo) {
        state[i] = globalInfo[i];
    }
},
  },
  actions: {//异步
    normalLogin({commit}, userInfo) {//这种只能传一个参数，参数可以是对象
         commit('set_userInfo', userInfo);
    }
  },
  modules: {
  },
  
 
})
