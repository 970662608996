import store from '@/store'
var code_mode = false;//true 完整模式 false 缺省模块调试
//设置环境语言
var languagebox = ["en","zh"]
//判断访问终端
var browser = {
    versions: function () {
        var u = navigator.userAgent
        return {
            trident: u.indexOf('Trident') > -1, //IE内核
            presto: u.indexOf('Presto') > -1, //opera内核
            webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
            ios: u.indexOf('Mac') > -1, //ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
            iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1, //是否iPad
            webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
            weixin: u.indexOf('MicroMessenger') > -1, //是否微信 （2015-01-22新增）
            qq: u.match(/\sQQ/i) == " qq" //是否QQ
        };
    }()
}

var global_var = {
    f_language:"sys",
    u_language:"",
    platform: browser.versions
}
//切换语言
function changelang(lang) {
    if (!code_mode) {
        localStorage.clear();
    }
    setTimeout(function () {
        
    }, 500);
}
var getdata = function () {
    if (!code_mode) {
        var tmpdata_ = window.localStorage;
        global_var.f_language = tmpdata_["LanguageSet"] ? tmpdata_["LanguageSet"] : "zh";

        if (autologin.iflogin == true) AutoLoginCheck(autologin.loginfun)
    }
    else if (global_var.platform) {
        readFile(fileName, setdata);

        function setdata(tmpdata) {
        
            global_var.f_language = tmpdata["LanguageSet"] ? tmpdata["LanguageSet"] : "sys";
        }
    }
    else {
        try {
    
            global_var.f_language = fileDialog4Js.getJsStorage('LanguageSet');
    
        } catch (e) {
            var tmpdata_ = window.localStorage;
       
            global_var.f_language = tmpdata_["LanguageSet"] ? tmpdata_["LanguageSet"] : "zh";

          
        }

    }
}

//跟随浏览器语言系统
var setLanguage = function (i18n) {
    var language = global_var.f_language;
    if (global_var.platform) {
        if (language == "sys") {
            //语言随系统查询配置语言是否有对应项
            for (var item of languagebox) {
                if (navigator.language.indexOf(item) != -1) {
                    language = item;
                }
            }
            if (language == "sys") language = languagebox[0];//配置语言无系统语言时默认使用英文
        } 
    }
    if (language.indexOf("zh") != -1) {
        i18n.locale = "zh"
    }
    // else if (language.indexOf("en") != -1) {
    //     i18n.locale = "en"
    // }
    else {
        var pathname = window.location.pathname;
        if(pathname=="/ToyLibrary"){
            i18n.locale = "en"
        } else {
            i18n.locale = "zh"
            var a = document.createElement("a");
            a.href = "https://www.aoseed.com";
            a.target = "_blank";
            a.click();
        }
    }
    global_var.u_language = language;
}
const init =function (i18n) {
    setLanguage(i18n)
}
const previewimg = function(){
    $(document).one("click", ".colist img", function () {
        var imgs = this;
        var imgSrc = $(imgs).attr("src");
        $(".imgViewDom img").attr("src", imgSrc);
        $(".imgViewDom").css("display", "-webkit-box");
    })
    $(".imgViewDom").click(function () {
        var box = this;
        $(box).find("img").attr("src", "");
        $(this).hide();
    })
}
const mainWrapper = function(){
    var _w = window.innerWidth;
        var _h = window.innerHeight;
        var _height = _w * (680 / 1920);
       
        if (_height < 300) {
            _height = 576;
        }
        $(".el-carousel__container").css("height", _height + 'px');
}
export {
    init,
    changelang,
    getdata,
    previewimg,
    mainWrapper,
}