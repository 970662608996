import {
  Get,
  Post,
 // Put,
  //Patch,
  Delete,
  Gethtml
} from '@/api/request';

//const url = "http://192.168.1.128:5073";

export default {
   getTxt:(url,params)=>{
      return Get(url,params)
   },
   //底部email
   getEamilBook: (params) => {
    return Get('/Common/EamilBook', params);
  },
  //英文Email
  getEamilEn: (params) => {
    return Get('/ApplySeller/EamilBook', params);
  },
  //英文支持
  getSuxkidateDate:(params) =>{
    return Post('/Support/QueeyQuestion', params);
  },
  //中文支持
  getSuxkidateDates:(params) =>{
    return Get('/Forum/getAPPAdviceList', params);
  },
  //中文下载版本
  getdownlog:(params) =>{
   return Get('/ResourceCenter/getSoftUpdateInfo',params);
  },
  //英文下载版本
  getdownlogEn:(params) =>{
    return Get('/Downloads/getSoftUpdateInfo',params);
   },
  //中文首页Banner
   getbannerZh:(params) =>{
     return Get('/Index/GetBanners',params);
   },
 //英文首页Banner
 getbannerEn:(params) =>{
  return Get('/Index/GetBanners',params);
},
  //解决方案 solution
  getSolutionIndexTrainData:(params) =>{
    return Get('/Solution/IndexTrain', params);
  },
 
  //支持页面 support
  getSupportAdviceList:(params) =>{
    return Get('/Support/getUsualAdviceList',params);
  },
  //支持页面问题
  getSupportusual:(params) =>{
    return Get('/Support/getUsualAdviceList',params);
  },
  // //支持xkit页面 
  // getSuxkidateDate:(params) =>{
  //   return Post('https://en_biz.ime3d.com/Support/QueeyQuestion', params);
  // },

  //Contact us页面 
  getContactDate:(params) =>{
  return Post('/About/Contact', params);
},
//Contact us页面 
getResellerDate:(params) =>{
  return Post('/ApplySeller/EamilBook', params);
},
  //支持xkit页面 more
  getQuestionMoreDate:(params) =>{
    return Post('/Support/QuestionMore', params);
  },
  //关于我们 contactus 
  postContactData:(params)=>{
  //  return Post("/About/Contact",params);
  return Get("/About/Contact",params);
  },
  //返回登录状态和登录后用户基本信息：头像，名称等
  getHasUser: () => {
    //return Get(url+'/Index/GetBanners', params);
    return Get('/Login/isAdmin');
  },
  getlogin: (params) => {//登录
    // return Post('/Login/GetLoginInfo', params);
    return Post('/login/login', params);
  },
  getretrievePassword:(params) =>{ //获取注册验证码(注册)
    return Post('/login/getVeriCodeForRegister',params);
  },
  getExchangeCode:(params) =>{ //获取注册验证码(修改)
    return Post('/login/retrievePassword',params);
  },
  checkVeriCode:(params)=>{//验证Code
   return Post('/login/checkVeriCode',params)
  },
  getRegister: (params) => { //注册
    // return Post('/Register/GetRegisterList', params);
    return Post('/login/register', params); 
  },
  getthingsdata: (params) => { //获取万物列表数据
    //return Post('/Explore/Model', params);
    return Post('/Explore/Model', params);
  },

  upAvator: (params) => { //保存上传的头像
    return Post('/UserSpace/SaveAvator', params);
  },
  getWorksCount: (params) => { //个人作品总数
    return Get('/UserSpace/UserWorkCount', params);
  },
  getWorkData: (params) => {//个人作品数据
    return Post('awuser/findWorkByUserId', params);
},
  deleteWork: (params) => { //删除个人作品
    //return Post('/Community/DeleteCommunity', params);
    return Post('/awuser/deleteWork', params);
  },
  getWorkName: (params) => { //获取个人作品名称
    // return Post('/Community/GetWorkName', params);
    return Post('/Community/GetWorkName', params);
  },
  getWorkFile: (params) => { //获取个人作品文件
    //return Post('/Community/WorkDownLoad', params);
    return Post('/awuser/findWorkByUserId', params);
  },
  changePassword: (params) => {//修改密码
   //return Post('/UserSpace/ChangePassword', params);
   return Post('/login/updatePassword', params);
  },
  
  getUpworkData:(params) => { //上传作品
    return Post('/Community/SaveMyWork',params);
  },
  getudworekdate:(params)=>{//作品详情
    return Post('/awuser/findWorkById',params);
  },
  getedituploadworkdate:(params)=>{//编辑作品
    // return Post('/Community/getWorkEditInfo/',params);
    return Post('Community/EditContentSave' ,params);
  },
  geteditworkdate:(params)=>{//编辑作品
    // return Post('/Community/getWorkEditInfo/',params);
    return Post('Community/getWorkEditInfo' ,params);
  },
  
  getuserpar:(params)=>{//获取头像
    return Post('/UserSpace/IfLogin ',params);
  },
  getkidapp:(params)=>{
     return Get ('/Product/getAppList',params)
  },
  getkiddetail:(params) => {
   return Get ('/Product/kidsDetail',params)
  },
  getUpload:(params)=>{
    return Post('/file/addFile',params,{
      headers:{
        'content-Type':'multipart/form-data'
      }
    });
  },
  getworksUpload:(params)=>{
    return Post('/Community/UploadFile',params,{
      headers:{
        'content-Type':'multipart/form-data'
      }
    })
  },
  getactivate:(params)=>{
    return Get('/Register/Activate',params,{
      headers:{
        'content-Type':'multipart/form-data'
      }
    })
  },


 getMatchwork:(params)=>{
  return Post('/login/findForMatch2023',params,{
  })
 },
 getfileByCode:(params)=>{
  return Post('/file/findFileByCode',params,{
  })
 },

 deleteAccound:(params)=>{//注销用户
  return Post ('/auth/LogoutUser',params,{
  })
 },
 getfindConstByName:(params)=>{//获取软件
  return Post ('/explorationName/findConstByName',params,{})
 },
 getThingsClassList:(params)=>{//万物类型
 return Post('/awclass/findAll',params)
 },
 getTagThings:(params)=>{//上传万物标签
     return Post('/tag/findAllAwTag',params)
 },
 getSoftList:(params)=>{//上传万物APP
  return Post('/explorationName/findConstByName',params)
 },
 //查询容量
 getCapacity:(params)=>{
    return Post('/awuser/findCapacityByUserId',params)
 },
 //通过Code删除文件
 deleteFileByCodes:(params)=>{
  return Post('/file/deleteFileByCode',params)
},
//上传模型
addUserWork:(params)=>{
   return Post("/awuser/addUserWork",params)
},
//编辑我的模型
editorMyModel:(params)=>{
  return Post("/awuser/updateUserWork",params)
},
//通过id获取我的模型详情
 getmymodelbyid:(params)=>{
  return Post("/awuser/findWorkById1",params)
 },
// 删除我的模型
 delectModelbyid:(params)=>{
  return Post("/awuser/deleteWork",params)
 },
 //修改头像  编辑用户资料
 getAvator:(params)=>{
  return Post("/auth/updateAwUser",params)
 },
//寻找问题页面
 getSearch:(params)=>{
  return Get("/About/queryChat",params)
 },
 //通过分类Value获取万物列表
 getThingsListByClassValue:(params)=>{
  return Post("/things/findThingsHeadByValue",params)
 },
 //获取万物热门列表(新增)
 getHotList:(params)=>{
    return Post('/things/findHotThings',params)
 },
 //获取万物最新列表(新增)
 getNewestList(params) {
  return Post('/things/findNewThings',params)
},
//获取万物精选列表
getSelectedList(params){
  return Post('/things/findRecommendThings',params)
},
//通过分类Value获取万物列表
getThingsListByClassValue(params){
  return Post('/things/findThingsHeadByValue',params)
},
//根据关键字查询万物列表,万物搜索
getThingsListByName(params) {
  return Post('/things/findThingsHeadByName',params)
},
//获取探索一级列表
getExplorationClassList(params){
  return Post('/aweclass/findAll',params)
},
//根据分类Value查询X-MAKER探索列表
getExplorationListByClassValue(params){
   return Post('/exploration/findExplorationByValue',params)
},
//根据name查询探索
getExplorationByName(params){
   return Post('/exploration/findByName',params)
},
//获取快速玩转3条数据或设计下面的推荐列表
getQuickData(params){
  return Post('/const/findName',params)
},
//获取探索详情
getExploreDetailById(params){
  return Post('/exploration/findExplorationByIDAndUserId',params)
},
//获取课程详情
getCoursDetailById(params){
  return Post('/explorationLesson/findByExplorationId',params)
},
//获取课程推荐列表
getMoreCourse(params){
  return Post('/explorationLesson/findByLike',params)
},
//获取AppInfo
getAppInfo(params){
return Post('Downloads/findAppInfoByClassID',params)
},
 //大赛列表
 getContestList: (params) => {
  return Post('/contest/findAll', params)
},
//得到文件服务器文件
Gethtmls:(url,params)=>{
  return Gethtml (url,params)
},
//大赛报名
GetUploadInfo(params){
  return Post('contestWork/enroll',params)
},
//根据Id获取大赛
getContestdeta(params){
  return Post('contest/findByIdAndUserId',params)
},
//根据大赛id上传作品
  uplaodMatch(params){
  return Post('contestWork/upload',params)
  },
  //查询所有参赛作品文件
  getWorksByContestId(params){
    return Post ('contestWork/findApproved',params)
  },
  //查询所有参赛作品文件
  getWorksByFindAward(params){
    return Post ('contestWork/findAward',params)
  }
}

