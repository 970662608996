<template>
  <div id="app">
    <router-view />
    <loginWin />
  </div>
</template>
<script>
import {init} from '@/components/unit/config_init'
import loginWin from "@/components/Login/Login.vue";
export default ({
  name: "App",
  components: {
    loginWin,
    },
  data() {
    return {
      name:"",
      password:"",
      currentTime: "" // 获取当前小时数
    };
  },
  methods:{
     login(){
      this.name = localStorage.getItem("userName")==null?"":localStorage.getItem("userName"),
      this.password = localStorage.getItem("password")==null?"":localStorage.getItem("password")
      let data = {
            "token": "abcdefg",
            "timestamp": new Date().getTime(),
            "data": {
            "isTemp": this.name ? 0:1,//1:游客    0:用户验证
            "loginType": 0,
            "name": this.name,
            "password":this.password,
            }
        }
        this.$axios.getlogin(JSON.stringify(data)).then((res)=>{
         if(res.errCode==0){
          this.$store.commit("set_userInfo", res.data);
          localStorage.setItem("aoseed_info", JSON.stringify(res.data));
         } else {
          this.$message(res.errMsg)
         }
        })
     },
  },
 mounted(){
  init(this.$i18n)
  var changgelangusge = localStorage.getItem("LanguageSet","")
       if(changgelangusge =="zh"){
        this.$i18n.locale="zh"
       } else if(changgelangusge =="en") {
        this.$i18n.locale ="en"
       }
       setInterval(() => {
       this.currentTime =new Date().getHours()
  }, 1000);
    },
  created(){
    const name = localStorage.getItem("userName");
      if (name !=undefined) {
        var data = {};
        data = JSON.parse(window.localStorage.aoseed_info);
        this.$store.commit("set_userInfo", data);
        this.sex = this.$store.state.userInfo.gender
      }
  },
  watch: {
    currentTime:{
      handler(newVal,oldVal){
         if(newVal == 0){
          this.login();
         }
      }
    },
},
})
</script>

<style lang="scss">
#app {
  font-family:-apple-system，BlinkMacSystemFont， Segoe UI， Roboto， Droid Sans, Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif-apple-system，BlinkMacSystemFont， Segoe UI， Roboto， Droid Sans, Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
body,
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
</style>
