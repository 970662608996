import Vue from 'vue'
import { Lang } from "./lang/langlife"
import  VueI18n   from 'vue-i18n'
/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
 Vue.use(VueI18n)
function loadLocaleMessages() {
  const Lang_zh = {}
  const Lang_en = {}
  for (let key in Lang) {
    Lang_zh[key] = Lang[key]["zh"];
    Lang_en[key] = Lang[key]["en"];
  }
  const messages = {
    zh: Lang_zh,
    en: Lang_en
  }
  return messages
}
function change(type){
  var changgelangusge = localStorage.getItem("LanguageSet", type);
  if (changgelangusge == "zh") {
     return "zh";
  }
  else if(changgelangusge == "en") {
    return  "en";
  } else {
    return ""
  }
}
const i18n = new VueI18n ({
  locale:change() ,
  fallbackLocale: 'zh',
  messages: loadLocaleMessages(),
  silentFallbackWarn:true
})
 

export default i18n

