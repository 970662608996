export const Lang = {
    //路由
    "router_home":{zh:"AOSEED 爱创星球",en:"AOSEED All Originate from a Seed"},
    //头部 底部
    "linkRouter":{zh:"https://res.ime3d.com/",en:"https://x-maker-res2.s3.amazonaws.com/"},
    "XMAKER":{zh:"X-MAKER",en:"X-MAKER"},
    "XKIT":{zh:"X-KIT",en:"X-KIT"},
    "product":{zh:"产品",en:"Product"},
    "community":{zh:"社区",en:"Community"},
    "shop":{zh:"商店",en:"Store"},
    "support":{zh:"支持",en:"Support"},
    "down":{zh:"下载" , en:"Download"},
    "P1":{zh:"/img/HUB.7714ac18.png",en:"/img/P3.bfdd6582.png"},
    "watch":{ zh:"观看视频", en:"Video"},
    "buy":{ zh:"立即购买", en:"Buy Now"},
    "language":{zh:"简体中文",en:"English"},
    "things":{zh:"万物",en:"Things"},
    "explore":{zh:"探索",en:"Explore"},
    "Competitions":{zh:"活动",en:"Event"},
    "KITLink":{zh:"https://www.ime3d.com/Community",en:"https://en.ime3d.com/Community"},
    "XMAKER_Link":{zh:"https://detail.tmall.com/item_o.htm?id=696784700850",en:"https://shop.aoseed.com/products/aoseed-bundles-creative-explorer-pack"},
    "XKIT_Link":{zh:"https://aoseed.tmall.com/",en:"https://shop.aoseed.com/products/x-kit-robotics-toy-factory-3d-printer"},
    "aolink":{zh:"https://www.ime3d.com",en:"https://en_biz.ime3d.com"},
    //aoseed 首页
     "index_sec1_tit1":{zh:"让3D打印放飞孩子的创想",en:"Unleash Children's Creativity with 3D Printing"},
     "index_sec1_content1":{zh:"玩玩具不如创造玩具，让孩子在创造中学习",en:"Making toys is more fun than just playing with them. Inspire children to learn through making creations."},
     "index_sec1_title2":{zh:"亲子互动",en:"Parent-child Interaction"},
     "index_sec1_content2":{zh:"陪着孩子去发明创造，最富创意的亲子时光",en:"Join your child on a journey of invention and creativity for unforgettable parent-child moments."},
     "index_sec1_title21":{zh:"创造玩具",en:"Create Toys"},
     "index_sec1_content21":{zh:"为孩子创造无限玩具，孩子的玩具创造工厂",en:"A toy factory for children to create unlimited toys."},
     "index_sec1_title22":{zh:"科创教育",en:"STEAM Education"},
     "index_sec1_content22":{zh:"让孩子在创造中学习，科创教育的明智之选",en:"Inspire children to learn through making creations - a wise choice for STEAM education."},
     "index_sec1_title3":{zh:"为每一个创意保驾护航",en:"Meet Our Two Innovative Products"},
     "index_sec1_content3":{zh:"AOSEED一直在做帮用户生产创意的产品",en:"AOSEED has been committed to help users bring their creative ideas to life with its innovative products."},
     "index_sec2_title1":{zh:"小智3D打印机",en:"3D Printer"},
     "index_sec2_content1":{zh:"百变创意机器人",en:"Unlimited Robot Creation Kit"},
     "index_sec2_more":{zh:"了解更多创意作品",en:"Learn More"},
     "index_sec3_title1":{zh:"AOSEED爱创星球，秉持“玩玩具不如创造玩具，让孩子在创造中学习”的品牌理念，为孩子创新研发了能够创造无限可能的全系列产品，打造专属于孩子的“玩具创造工厂”，重塑孩子在数字时代创造的方式。",en:"With the brand concept of “Making toys is more fun than just playing with them. Inspire children to learn through making creations”, AOSEED has innovated and developed a whole range of products that offer endless possibilities, creating a 'Toy Factory' exclusively for children. With AOSEED, we can reshape the way children create in the digital age."},
     "index_sec3_more":{zh:"了解更多品牌故事",en:"Learn More"},
     "index_sec4_title":{zh:"AOSEED荣获各项荣誉与资质",en:"Certificate & Award"},

     //产品 X-KIT页面
     "KIT_bannertit1":{zh:"百变机器人",en:" Unlimited Robot Creation Kit"},
     "KIT_bannertit2":{zh:"编程设计 | 智能设计 | 创造神器 | 数字积木",en:"Intuitive app with smart hub allow kids to design, to 3D print, to code and to play their own robot toys"},
     "KIT_hon_title1":{zh:"3D打印机的最佳搭档", en:"Best Mate To 3D Printer"},
     "KIT_ars1":{zh:"X-KIT 借助其应用程序、集线器和巧妙的代码，降低了所有年龄段儿童发现 3D 打印奇迹的障碍。使用 X-KIT 设计的零件可以在任何 3D 打印机上打印。该应用程序具有预设参数，可确保打印出适合 Hub 的完美尺寸，同时仍然允许完全的创意自由来设计任何东西。",en:" X-KIT lowers the barrier for kids of all ages to discover thewonders of 3D printing with the help of its app, hub anclever code. Parts designed with X-KIT can be printed on any 3D printer. The app has pre-set parameters that ensure prints come out perfectly sized for the Hub, while still allowing complete creative freedom to design anything."},
     "KIT_hon_title2":{zh:"儿童创意机器人工厂",en:"Robot Factory For Kids"},
     "KIT_ars2":{zh:"孩子们喜欢玩具也喜欢创造。为什么不让孩子们创造自己的玩具呢？使用X-KIT，您可以拥有一个机器人工厂，建立一个机器人王国。我们还在 X-KIT 应用程序中提供了 60 多个精心设计的机器人模板，从简单到高级。从模板中获得灵感或从头开始构建令人难以置信的东西。",en:"X-KIT lowers the barrier for kids of all ages to discover the wonders of 3D printing with the help of its app, hub and clever code. Parts designed with X-KIT can be printed on any 3D printer. The app has pre-set parameters that ensure prints come out perfectly sized for the Hub, while still allowing complete creative freedom to design anything."},
     "KIT_hon_title3":{zh:"无限的游戏方式", en:"Unlimited Ways To Play"},
     "KIT_ars3":{zh:"在恐龙世界中驾驶丛林卡车过桥，或为智能机器人编程以跟随轨道。有超过 40 个程序供孩子们从应用程序中选择，提供无限的可能性。我们无穷无尽的游戏方式每时每刻都在激发您孩子的想象力。",en:"Drive a jungle truck across a bridge in a dinosaur world, or program a smart robot to follow the tracks. There are over 40 programs for kids to choose from the app providing endless possibilities. Your child's imagination is stimulated at every turn with our endless ways to play."},
     "KIT_hon_title4":{zh:"简单的设计智造",en:"Simple Way To Create"},
     "KIT_ars4":{zh:"X-KIT 库中的模板是完全可定制的，因此孩子们可以对现有模型进行自己的变体。通过这种方式，孩子们可以快速进行设计，同时仍然可以完全控制创意。",en:"Templates in the X-KIT library are fully customizable so kids can make their own variations of existing models. This way, kids can be on their way with designing quickly, while still having full creative control."},     
     "KIT_work_title":{zh:"工作流程",en:"How it Works"},
     "KIT_work_content":{zh:"X-KIT通过模块化3D设计、3D打印和有趣的图形化编程，让您快速打造各种玩具机器人。发挥无限的创意。",en:"   X-KIT let you rapidly create various toy robots by modularized 3D design, 3D printing and fun coding. Then go nuts and play!"},
     "KIT_play_title":{zh:"App", en:"The App"},
     "KIT_play_content":{zh:"X-KIT 创意设计APP集成了丰富的创意模型资源，4大类共计超过60个预置机器人案例的模型，所有模型支持直接导出为STL文件；所有模型资源支持用户进行创意修改后，导出为STL格式文件使用；机器人案例模型中所有配件均可相互交叉组装使用，拓展更多创意。",en:" It allows kids to design and print their own robots, and then to code and control the robots."},
     "KIT_play_title1":{zh:"模块化3D设计",en:"Modularized 3D Design"},
     "KIT_play_content1":{zh:"模块化的3D建模设计，通过简单拖拽即可实现想法，设计模块库中含有600多种的不同组件让用户自由搭配",en:"The modularized 3D design provides you 650+ robot components to create new models simply by drag&snap parts into place. Special pieces are provided to adapt LEGO blocks in your design."},
     "KIT_play_title2":{zh:"图形化编程",en:"Graphical Coding"},
     "KIT_play_content2":{zh:"图形化编程大大降低了编程的门槛，让孩子锻炼编程思维、逻辑思维能力。支持卡片式编程和积木式编程两种图形化编程模式，适合各年龄段学生使用，玩法案例库中预置了40个以上完整的程序，可供用户直接选择调用实现对应功能，或者基于预置程序进行自定义编辑修改。",en:"There are 2 intuitive graphical coding modes for kids with different levels of proficiency. to command robots through program. Plus, 40+ well-designed programs are for kids to get start with."},
     "KIT_play_title3":{zh:"程序遥控",en:"Remote Control"},
     "KIT_play_content3":{zh:"设计、打印和组装好的机器人可以通过程序或遥控进行控制，支持多样化的活动开展方式，寓教于乐。", en:"A powerful console including directional controls, servos, 6 pre-set motions, and custom code, gives you full control over the robots."},
     "KIT_play_title4":{zh:"可视化搭建",en:"Assemble"},
     "KIT_play_content4":{zh:"所有预置案例的模型组装过程，提供了3D可视化的分步骤组装以及文字说明。", en:"All robot designs are equipped with animated assembling instructions. Use matching accessories to realize more creative designs."},
     "KIT_more":{zh:"探索更多",en:"View More"},
     "KIT_hub_title":{zh:"机体Hub",en:"The Hub"},
     "KIT_hub_content":{zh:"X-KIT Hub机体上集成了电机、红外传感器、LED指示灯和蓝牙等电子模块，包含了28个可用于结构拓展的标准的连接孔、8个可用于连接不同电子模块进行功能拓展的标准接口、万向轮等结构。",en:"3D printed parts are built up on the hub and coded programs are executed by the hub. Hub brings your 3D creations to life."},
     "video5":{zh:"http://www.ime3d.com/_data/file/5.mp4",en:"https://en-ime3d-res.s3.us-west-1.amazonaws.com/video/xkit/5.mp4"},
     "video6":{zh:"http://www.ime3d.com/_data/file/6.mp4",en:"https://en-ime3d-res.s3.us-west-1.amazonaws.com/video/xkit/6.mp4"},
     "video7":{zh:"http://www.ime3d.com/_data/file/7.mp4",en:"https://en-ime3d-res.s3.us-west-1.amazonaws.com/video/xkit/7.mp4"},
     "video8":{zh:"http://www.ime3d.com/_data/file/8.mp4",en:"https://en-ime3d-res.s3.us-west-1.amazonaws.com/video/xkit/8.mp4"},
     //产品 X-MAKER 页面
     "MAKER_draw":{zh:"/img/draw1.c0fa72e9.png",en:"/img/drawen.b5f19c69.png"},
     "MAKER_shop":{zh:"https://aoseed.tmall.com/",en:"https://shop.aoseed.com/collections/all"},
     "MAKER_first":{zh:"/img/pic001.e03ba3db.png", en:"/img/zu13.6bfccf90.png"},
     "MAKER_banner_title":{zh:"小智3D打印机",en:"3D Printer"},
     "MAKER_banner_textArr":{zh:"游戏化设计 | 智能一键打印 | 创造神器 | 玩具工厂",en:"Gamify Design App | One-Press Printing | Make Anything | Toy Factory"},
     "MAKER_sec1_title1":{zh:"不仅是3D打印机，更是孩子玩具创造工厂",en:"X-MAKER ｜ A Smart 3D Printer with Gamification 3D Design App"},
     "MAKER_sec1_pArr1" :{zh:"X-MAKER智能多功能3D打印机就是探索创造力的神器。它配备功能强大的App，内有海量原创3D模型，开箱就能打印，让3D打印智造万物从此变得简单，每个家庭都能轻松驾驭",en:"X-MAKER 3D Printer, with all-new gamification 3D design App and easy-to-use one-press printing, dramatically lowers the barriers of 3D design and 3D printing to help children and adults make their creativity touchable easier than ever."},
     "MAKER_title21":{zh:"激发孩子创造力的好伴侣",en:"The Best Way to Unleash Children’s Creativity"},
     "MAKER_title3":{zh:"万物模型直接打印，无限创意可按需创造",en:"Numerous Creative Models Available"},
     "MAKER_pArr3":{zh:"设计师团队原创设计的数字化“万物”仓库，包含无限创意，开箱即可一键3D打印出高品质作品",en:"Just browse through the ever-expanding catalog of creative models, find something you like and press print."},
     "MAKER_title4":{zh:"玩转个性定制小程序，零门槛实现专属作品",en:"Gamified 3D Design Gadgets - Make Creation Touchable"},
     "MAKER_pArr4":{zh:"15款游戏化3D设计“小程序”，手绘建模、旋转建模、积木建模、参数建模丰富主题与玩法在兴趣中释放你的想象力，轻松圆你设计师的梦",en:'There are 15 gamified 3D design "mini-programs". All are super easy to DIY your digital creations.'},
     "MAKER_title5":{zh:"图形化自由设计软件，满足任意进阶创意需求",en:"Graphic & Modular Free 3D Design Apps for Further Creation"},
     "MAKER_pArr5":{zh:"两款自由设计软件，“数字积木”图形模块设计与“数字陶泥”自由雕塑造型，兼容任意3D模型，帮助你实现任何创意",en:'The two free design softwares, "digital building blocks" graphic module design and "digital clay" free sculpture modelling, help you realize your creativity.'},
     "MAKER_title6":{zh:"高配专业功能，兼顾简单好用与强大性能",en:"A Reliable and Powerful Desktop 3D Printer"},
     "MAKER_title10":{zh:"产品参数",en:"Specifications"},
     "MAKER_sec1_p1":{zh:"游戏化APP",en:"Gamification App"},
     "MAKER_sec1_p2":{zh:"智能一键打印",en:"One-Press Printing"},
     "MAKER_sec1_p3":{zh:"3.5寸触控屏",en:"3.5’’ Touch Screen"},
     "MAKER_sec1_p4":{zh:"免调平",en:"Leveling-Free"},
     "MAKER_sec1_p5":{zh:"WiFi高速传输",en:"10mb/s High Speed Wi-Fi"},
     "MAKER_sec1_p6":{zh:"断电续打",en:"Power-Loss Recovery"},
     "MAKER_sec1_p7":{zh:"玩具工厂",en:"Toy Factory"},
     "MAKER_sec1_p8":{zh:"制造万物",en:"Make Anything"},
     "MAKER_sec2_title":{zh:"玩具创造工厂",en:"Toy Factory"},
     "MAKER_sec2_content":{zh:"孩子的玩具工厂，告别传统玩具，极低的成本、随时创造", en:"Help children make their creativity touchable through 3D printer easier than ever. It's a toy factory for children at a really affordable price."},
     "MAKER_sec2_title11":{zh:"创造中学习",en:"Learn through creating"},
     "MAKER_sec2_content11":{zh:"创造玩具的过程中，更能培养孩子的想象力和动手能力，让3D打印放飞孩子创想", en:"Making toys with 3D printing encourages children's imagination and hands-on ability, and enables them to unleash their creative potential."},
     "MAKER_sec2_title22":{zh:"数字积木",en:"Digital Building Blocks"},
     "MAKER_sec2_content22":{zh:"用数字化方法进行积木搭建与创造，轻松拓展无限量的作品创意", en:"Use digital methods to remove the limitations of traditional building blocks for unlimited creative works."},
     "MAKER_sec21_title":{zh:"开箱即可使用",en:"Easy-to-use"},
     "MAKER_sec21_content":{zh:"创造玩具的打印机，重要的是简单易用",en:"The Most Easy-to-use 3D Printer with One-press Printing Feature"},
     "MAKER_sec21_title1":{zh:"智能一键打印",en:"One Press Printing"},
     "MAKER_sec21_content1":{zh:"高速WIFI直连，配合智能优化算法，媲美普通喷墨打印机的智能一键打印",en:"Experience ultra-fast and reliable WIFI direct connection with intelligent optimization algorithm, comparable to the one press printing of ordinary inkjet printers."},
     "MAKER_sec21_sec3_tit":{zh:"亲子陪伴",en:"Parent-child Interaction"},
     "MAKER_sec21_sec3_p":{zh:"与孩子一起发明创造，最富创意的亲子时光",en:"Join your child on a journey of invention and creativity for unforgettable parent-child moments."},
     "MAKER_sec21_sec3_tit1":{zh:"科创教育",en:"STEAM Education"},
     "MAKER_sec21_sec3_p1":{zh:"玩玩具不如创造玩具，让孩子在创造中学习",en:"Inspire children to learn through making creations - a wise choice for STEAM education."},
     "MAKER_more":{zh:"万物探索",en:"Explore"},
     "MAKER_more1":{zh:"体验App",en:"Download"},
     "MAKER_sec6_h":{zh:"自适应调平",en:"Leveling Free"},
     "MAKER_sec6_p":{zh:"创新免调平结构可靠好用",en:""},
     "MAKER_sec6_h1":{zh:"柔性磁吸底板",en:"Flexible & Magnetic Baseplate"},
     "MAKER_sec6_p1":{zh:"轻松弯折就能取下心爱作品",en:""},
     "MAKER_sec6_h2":{zh:"超高打印品质",en:"Excellent Print Quality"},
     "MAKER_sec6_p2":{zh:"创新驱动算法，保证打印精度",en:""},
     "MAKER_sec6_h3":{zh:"热温打印平台",en:"Heating Platform"},
     "MAKER_sec6_p3":{zh:"打印不翘边兼容多种耗材",en:""},
     "MAKER_sec6_h4":{zh:"3.5寸彩色触摸屏",en:"3.5” Touch Screen"},
     "MAKER_sec6_p4":{zh:"全新UI交互只为简单易用",en:""},
     "MAKER_sec6_h5":{zh:"断电续打",en:"Power-Loss Recovery"},
     "MAKER_sec6_p5":{zh:"停电自动记忆，复电继续打印",en:""},
     "MAKER_sec6_h6":{zh:"超静音3D打印",en:"Excellent Noise Control"},
     "MAKER_sec6_p6":{zh:"静音电机驱动技术，无噪音污染",en:""},
     "MAKER_sec6_h7":{zh:"专业切片软件",en:"Slicing Software"},
     "MAKER_sec6_p7":{zh:"兼容专业3D设计模型",en:""},
     "MAKER_log":{zh:"点击图标了解App介绍",en:"Click icon for more infomation"},
     "MAKER_sec3_tit":{zh:"搭载独立切片软件X-PRINT",en:"Slicing software X-PRINT"},
     "MAKER_sec3_p":{zh:"满足更多用户专业需求",en:"For more professional needs"}, 
     "MAKER_morebox":{zh:"体验X-PRINT",en:"Download"}, 
     "MAKER_secj_tit":{zh:"可降解的环保材质，家庭使用安全可靠",en:"Bio-degradable & Environment-friendly Material"},
     "MAKER_secj_p":{zh:" 新鲜首推渐变色改性PLA耗材，打印多彩创意作品，同时安全又放心",en:"Novel gradient PLA filaments for colorful creative works and whole family safety."},
     "MAKER_buy":{zh:"去购买",en:"Buy Now"}, 
     "MAKER_sec10_p1":{zh:"产品名称:",en:"Name:"},
     "MAKER_sec10_p11":{zh:"X-MAKER小智3D打印机",en:"X-MAKER 3D printer"},
     "MAKER_sec10_p2":{zh:"打印成型尺寸:",en:"Maximum build volume:"},
     "MAKER_sec10_p21":{zh:"150*150*150mm",en:"150*150*150mm"},
     "MAKER_sec10_p3":{zh:"型 号:",en:"Model:"},
     "MAKER_sec10_p31":{zh:"V4.0",en:"V4.0"},
     "MAKER_sec10_p4":{zh:"机器尺寸:",en:"Printer dimension:"},
     "MAKER_sec10_p41":{zh:"360*360*380mm",en:"360*360*380mm"},
     "MAKER_sec10_p5":{zh:"喷嘴温度:",en:"Nozzle temp"},
     "MAKER_sec10_p51":{zh:"≤260℃",en:"≤260℃"},
     "MAKER_sec10_p6":{zh:"包装尺寸:",en:"Package dimension:"},
     "MAKER_sec10_p61":{zh:"440*440*460mm",en:"440*440*460mm"},
     "MAKER_sec10_p7":{zh:"热床温度:",en:"Bed temp:"},
     "MAKER_sec10_p71":{zh:"≤110℃",en:"≤110℃"},
     "MAKER_sec10_p8":{zh:"净重(N.W.):",en:"Net weight:"},
     "MAKER_sec10_p81":{zh:"10.9Kg",en:"10.9Kg"},
     "MAKER_sec10_p9":{zh:"喷嘴直径:",en:"Nozzle diameter:"},
     "MAKER_sec10_p91":{zh:"0.4mm",en:"0.4mm"},
     "MAKER_sec10_pp1":{zh:"毛重(N.W.):",en:"Grossweight:"},
     "MAKER_sec10_pp11":{zh:"13.45Kg",en:"13.45Kg"},
     "MAKER_sec10_pp2":{zh:"输 入:",en:"Input:"},
     "MAKER_sec10_pp21":{zh:"200-240V~， 50/60Hz， 1.6A",en:"AC 100V-120V~， 50/60Hz,3.2A      200-240V~， 50/60Hz,1.6A"},
     "MAKER_sec10_pp3":{zh:"控制面板:",en:"Control panel:"},
     "MAKER_sec10_pp31":{zh:"3.5英寸触摸屏",en:"3.5-imch touch screen"},
     "MAKER_sec10_pp4":{zh:"USB2.0输出:",en:"USB2.0 Output:"},
     "MAKER_sec10_pp41":{zh:"5V 0.5A",en:"5V 0.5A"},
     "MAKER_sec10_pp5":{zh:"打印方式:",en:"Connectivity:"},
     "MAKER_sec10_pp51":{zh:"WiFi传输 / U盘",en:"Wi-Fi / U disk"},
     "MAKER_sec10_pp6":{zh:"CMIIIT ID:",en:"FCC ID:"},
     "MAKER_sec10_pp61":{zh:"2022DP12125",en:"2A35N-V04"},
     "MAKER_sec10_pp7":{zh:"支持文件格式:",en:"File type:"},
     "MAKER_sec10_pp71":{zh:"Xcode / Gcode",en:"Xcode / Gcode"},
     "MAKER_sec10_pp8":{zh:"耗材倾向性:",en:"Compatible filaments:"},
     "MAKER_sec10_pp81":{zh:"PLA / ABS",en:"PLA / ABS"},
     "MAKER_sec10_pp9":{zh:"配合软件:",en:"Supplied software:"},
     "MAKER_sec10_pp91":{zh:"X-MAKER App / X-PRINT",en:"X-MAKER App / X-PRINT"},
     "MAKER_sec10_ppp1":{zh:"软件语言:",en:"Language:"},
     "MAKER_sec10_ppp11":{zh:"中文 / 英文",en:"Chinese / English"},

    //支持首页
     "support_index":{zh:"产品支持",en:"Support"},
     "support_sec1tit":{zh:"欢迎使用 IME3D创新教育产品支持",en:"Welcome To IME3D Support"},
     "support_sec1conten":{zh:"我们很高兴为您提供帮助",en:"We are happy to help you"},
     "support_problem":{zh:"常见问答",en:"FAQ"},
     "support_account":{zh:"账号密码",en:"Account"},
     "support_useage":{zh:"软件使用",en:"Useage"},
     "support_other":{zh:"其它案例",en:"Other"},
     "contact":{zh:"联系我们",en:"Contact Us"},
     "Phone":{zh:"电话",en:"Phone"},
     "phone_num":{zh:"021-6071 9032",en:"+86-21-60719032"},
     "email":{zh:"邮箱",en:"Email"},
     "email_name":{zh:"service@aoweidig.com",en:"service@aoseed.com"},
     // 支持X-MAKER
     "sup_leran":{zh:"了解产品",en:"Learn Product" },
     "sup_select":{zh:"选购产品",en:"Buy Now" },
     "sup_load":{zh:"应用下载",en:"Download" },
     "sup_maker_banner":{zh:"X-MAKER 产品支持",en:"X-MAKER Support"},
     "sup_maker_res":{zh:"资源",en:"Resource"},
     "sup_maker_video":{zh:"教程视频",en:"How-to Video"},
     "sup_maker_video1":{zh:"X-MAKER 开箱介绍",en:"Unboxing the X-MAKER"},
     "sup_maker_video2":{zh:"X-MAKER 3D打印机快速使用指导",en:"Get started with X-MAKER App"},
     "sup_maker_video3":{zh:"X-MAKER App快速使用指导",en:"X-MAKER 3D printer Quick Guide"},
     "sup_maker_video4":{zh:"X-MAKER 3D打印机日常使用维护",en:"Use and Purchase of Filaments"},
     "sup_maker_video5":{zh:"3D打印耗材使用和购买",en:"Maintenance Guide for X-MAKER 3D Printer"},  
     "sup_maker_list1":{zh:"资源",en:"Resourse"},
     "sup_maker_list2":{zh:"教程视频",en:"How-to Video"},
     "sup_maker_list3":{zh:"常见问题",en:"FAQ"},
     "sup_maker_list4":{zh:"联系我们",en:"Contact Us"},
     
    //支持X-XKIT
     "sup_kit_banner":{zh:"X-KIT 产品支持",en:"X-KIT Support"},
     "sup_problem":{zh:"疑难问题",en:"Troubleshootings"},
    //下载
    "down_text1":{zh:"X-MAKER同时支持 iOS, Android，iPadOS，Windows 系统",en:"X-MAKER is available on iPad, iPadOS, Android and Windows."},
    "down_bang":{zh:"版本号： ",en:"Version:"},
    "down_bang_a":{zh:"更新日志",en:"Update Log"},
    "down_win":{zh:"Windows版",en:"Windows"},
    "down_load":{zh:"立即下载",en:"Download"},
    "down_sup_win":{zh:"支持Windows7及以上电脑",en:"Windows7 or above"},
    "down_ipad":{zh:"iPad版",en:"iPad"},
    "down_store":{zh:"AppStore安装",en:"AppStore"},
    "down_scan":{zh:"扫一扫安装",en:"Scan to Install"},
    "down_sup_ipad":{zh:"支持iPadOS14.0及以上平板",en:"iPadOS 14.0 or above"},
    "down_an":{zh:"Android版",en:"Android Tablet"},
    "down_recommend":{zh:"推荐使用应用宝、小米商城等主流应用商店下载",en:"GooglePlay"},
    "down_sup_an":{zh:"支持Android10及以上平板",en:"Android Tablet 10.0 or above"},
    "down_print_title":{zh:"X-PRINT 切片软件支持Windows设备上使用",en:"X-PRINT is available on Windows."},
    "down_print_bang1":{zh:"版本号：",en:"Version:"},
    "down_print_title2":{zh:"X-MAKER 固件升级包",en:"X-MAKER 3D Printer Update"},
    "down_print_bang2":{zh:"版本号： ",en:"Version:"},
    "down_kit_text":{zh:"X-KIT 同时支持 iPadOS，Android，Windows设备",en:"X-KIT is available on iPad, Android Tablet and Windows."},
    "down_Kit_bang1":{zh:"版本号：",en:"Version:"},
    "down_content":{zh:"更新内容",en:"Update Content"},
    //底部
    "JDshop":{zh:"京东商城",en:"Amazon"},
    "JDshopLink":{zh:"https://aoseed.tmall.com/",en:"https://shop.aoseed.com/"},
    "foot_contactLink":{zh:"https://www.ime3d.com/About/index#3",en:"/contactUs"},
    "foot_aboutLink":{zh:"https://www.ime3d.com/About/index",en:"/aboutUs"},
    "Tshop":{zh:"天猫商城",en:"Shopify"},
    "foot_about":{zh:"关于",en:"About"},
    "foot_contact":{zh:"联系我们",en:"Contact Us"},
    "foot_aboutUs":{zh:"关于我们",en:"About Us"},
    "foot_newContent":{zh:"订阅我们获取最新资讯服务与内容更新！",en:"Get our Newsletter and gain latest updates!"},
    "foot_enter":{zh:"输入邮箱",en:"Enter Your Email"},
    "foot_term":{zh:"用户协议",en:"Terms of Use"},
    "foot_privacy":{zh:"使用条款",en:"Privacy Policy"},
    "foot_copyright":{zh:"版权所有Copyright©2023 遨为（上海）数字技术有限公司",en:"Copyright©2023"},
    "foot_hu":{zh:"沪ICP备13009905号-3",en:"Shanghai ICP 13009905 -3"},
    "register":{zh:"获取验证码",en:"Get verification code"},
    "register_successly":{zh:"注册成功",en:"Registered successfully"},
    "success_code":{zh:"成功获取验证码",en:"Successfully obtained verification code"},
    "faile_code":{zh:"获取验证码失败",en:"Failed to obtain verification code"},
    "info_Account":{zh:"账户",en:"Account"},
    "info_Username":{zh:"名字",en:"Username"},
    "info_Email":{zh:"邮箱",en:"Email"},
    "Update_password":{zh:"修改密码",en:"Change Password"},
    "update_code":{zh:"邮箱验证码",en:"Get verification code"},
    "update_passwords":{zh:"新密码",en:"New password"},
    "update":{zh:"确认",en:"Update"},
    "Noworks":{zh:"没有作品",en:"No works"},
    "forgetpassword":{zh:"修改密码",en:"Find Your Account"},
    "forget_email":{zh:"邮箱",en:"Email address"},
    "forget_Code":{zh:"验证码",en:"Verification code"},
    "size":{zh:"尺寸",en:"Size"},
    "share":{zh:"分享",en:"share"},
    "Submit":{zh:"确认",en:"Submit"},
    "Cancel":{zh:"取消",en:"Cancel"},
    "word_length":{zh:"密码至少6位数",en:"Password must be at least 6 digits"},
    "mod_succ":{zh:"更新成功",en:"Modify successfully"},
    "enter_email":{zh:"请正确输入邮箱",en:"Please enter the Email"},
    "account":{zh:"还没有账号吗？",en:"Don't have an account?"},
    "Sign_Up":{zh:"注册",en:"Sign Up"},
    "login_name":{zh:"账号",en:"User Name"},
    "login_password":{zh:"密码",en:"Password"},
    "forget_word":{zh:"忘记密码",en:"Forgot password"},
    "log_in":{zh:"登录",en:"Log In"},
    "enter_name":{zh:"请输入你的名字",en:"Please enter your User Name."},
    "enter_password":{zh:"请输入密码",en:"Please enter your Password."},
    "res_account":{zh:"有账户吗？",en:"Have an account?"},
    "con_password":{zh:"确认密码",en:"Confirm Password"},
    "signing":{zh:"注册即表示您同意",en:"By signing up,you agree to"},
    "res_terms":{zh:"用户协议",en:"Terms"},
    "creaAcc":{zh:"创建帐户",en:"Create Account"},
    "email_code":{zh:"请输入你的验证码",en:"Please enter your Verification code."},
    "differTwice":{zh:"密码输入不一致，请重新输入",en:"The passwords are different twice."},
    "check_word":{zh:"请检查你的密码",en:"Please check your password."},
    "sorry":{zh:"抱歉，稍后再试",en:"Sorry,try again later"},
    "changeCode":{zh:"验证码不能为空",en:"The verification code cannot be empty"},
    "login_work":{zh:"我的作品",en:"My Works"},
    "login_set":{zh:"个人资料",en:"Settings"},
    "login_Acc":{zh:"账号设置",en:"My Account"},
    "login_out":{zh:"退出登录",en:"Sign Out"},
    "down_center":{zh:"下载中心",en:"DOWNLOAD CENTER"},
    "logout_ok": {zh:"确认注销",en:"Delete"},
    "logout_think":{zh:"我再想想",en:"Not now"} ,
    "logout_tip": {zh:"注销后，该账户内所有数据都将被清空，此操作无法恢复，请确认是否要注销？",en:"After delete, you will lose all data associated with your account. Are you sure?"},
    "logout_tips":{zh:"注销后账号所有数据将被销毁并不可找回，请谨慎操作。",en:"After delete, you will lose all data associated with your account."},
    "logout_user":{zh:"注销账户",en:"Delete Account"} ,
    "logfar": {zh:"注销成功",en:"Delete account successfully"},
    "welcome":{zh:"期待下次与您更好的相遇，如需再次使用，", en:"Look forward to meeting you soon."}, 
    "please": {zh:"请",en:"please"},
    "reregister":{zh:"重新注册",en:"Re-register"},
    "complete": {zh:"完成",en:"OK"},
    "errTip":{zh:"你输入的账户不存在",en:"Incomplete account or password"},
    "emailerror":{ zh:"邮箱格式有误",en:"Incorrect email address"},
    "login_password1":{zh:"输入6-12位数字、字母、符号（必填）",en:"Enter 6-12 characters, letters or numbers"},
    "agreement": {zh:"已阅读并同意我们的",en:"Read & Agree"},
    "privacy": {zh:"隐私政策",en:"Privacy Policy"},
    "noageement":{zh:"点击勾选，方可进行登录/注册",en:"Tick the box (on the bottom)to log in / register"},
    "lost_resetting": {zh:"重置密码",en:"Reset password"},
    "sendEmail":{zh:"发送邮件",en:"Send Mail"},
    "errEmail":{zh:"验证码已发送至邮箱，请登录邮箱查看验证码",en:" Please log in to your email to view the verification code"},
    "lost_next": {zh:"下一步",en:"Continue"},
    "level1": {zh:"启航创作达人",en:"Amethyst"},
    "level2": {zh:"探索创作达人",en:"Ruby"},
    "level3":{ zh:"成长创作达人",en:"Sapphire"},
    "level4":{ zh:"飞跃创作达人",en:"Emerald"},
    "level5": {zh:"卓越创作达人",en:"Diamond"},
    "save":{zh:"保存",en:"Save"},
    "registration_date":{zh:"注册时间",en:"Date to Register"},
    "man": {zh:"男",en:"Male"},
    "women": {zh:"女",en:"Female"},
    "sex": {zh:"性别",en:"Gender"},
    "username":{zh:"用户名",en:"Username"},
    "login_in":{zh:"请先登录",en:"Please log in"},
    "returnLogin":{zh:"返回登录",en:"Return to login"},
    "myWork": {zh:"我的模型",en:"MODELS"},
    "addtip": {zh:"最多添加5个标签",en:"Add up to 5 tags"},
    "modeltype1":{zh:"原创模型",en:"Original"},
    "uploadok": {zh:"上传成功",en:"Uploaded successfully"},
    "errorTip2": {zh:"服务异常",en:"Service exceptions"},
    "upload": {zh:"上传模型",en:"Upload"},
    "uptime":{zh:"时间升序",en:"Ascending order"},
    "downtime":{zh:"时间降序",en:"Descending order"},
    "modifyName":{zh:"用户名含有特殊字符，请重新输入",en:"The username contains special characters. Please re-enter"},
    "password_edit": {zh:"修改成功",en:"Reset successfully"},
    "skip":{zh:"暂不登录",en:"Skip"},
    "avatar":{zh:"更换头像",en:"Avatar"},
    "warmTip":{zh:"温馨提示",en:"Nota"},
    "PleaseSet":{zh:" 请为你的账号",en:"Please set a password for your account"},
    "setNew":{zh:"设置新的密码",en:""},
    "deleteTip":{zh:"确定删除该文件吗？",en:"Are you sure to delete the file?"},
    "modelName":{zh:"模型名称",en:"Name"},
    "modelHolder1": {zh:"输入模型名称，控制在10个字以内",en:"Enter the name of the model group within 10 characters"},
    "modelHolder2": {zh:"为你的模型添加一段描述，可以获取更多关注哦！",en:"Describe your model group to find more like-minded friends"},
    "modelHolder3": {zh:"图片至少上传1张，支持 GIF、JPG、PNG 等主流图片格式",en:"Upload at least 1 image. Supports GIF, JPG, PNG, etc., major image file formats"},
    "modelHolder4": {zh:"最多上传8个模型",en:"Upload no more than 8 models"},
    "modelHolder5": {zh:"选择合适的类别展示你的标签",en:"Choose the appropriate categories to display your model group"},
    "modelHolder6": {zh:"目前暂支持上传stl、xstl格式文件(50MB以内)",en:"Only STL, XSTL formats are supported(Cannot exceed 50M)"},
    "modelSource": {zh:"转载来源",en:"Reprint source"},
    "modelSource1": {zh:"",en:"The reprint source will be displayed on the model details page"},
    "modeldec": {zh:"模型简介",en:"Description"},
    "modelfrom":{zh:"模型来源",en:"Your models are"},
    "tag": {zh:"标签",en:"Tags"},
    "soft":{zh:"软件",en:"Soft"},
    "terms":{zh:"隐私设定",en:"Privacidad"},
    "public": {zh:"公开",en:"Public"},
    "tome": {zh:"只对自己",en:"Private"},
    "modeltype2":{zh:"转载模型",en:"Reproduced"},
    "picerror":{zh:"图片格式不正确",en:"Incorrect picture format"},
    "modelerror":{zh:"模型格式不正确",en:"Incorrect model format"},
    "types": {zh:"类别",en:"Category"},
    "lessThan":{zh:"不能超过",en:"Cannot exceed"},
    "submit":{zh:"提交",en:"Submit"},
    "preview":{zh:"预览",en:"Preview"},
    "addModel": {zh:"添加模型",en:"Add Model"},
    "nocapacity": {zh:"您的个人文件空间已满",en:"Storage is full"},
    "uploadTip1": {zh:"请填写名称",en:"Please fill out the name"},
    "uploadTip2": {zh:"请填描述",en:"Please fill out the description"},
    "uploadTip3": {zh:"至少上传一张图片",en:"Upload at least 1 picture"},
    "uploadTip4": {zh:"需要上传模型",en:'Model Uploading needed'},
    "cover": {zh:"封面",en:"Cover"},
    "findNone":{zh:"到底了",en:"End"},
    "course_constvalue":{zh:"适合年龄",en:"Age"},
    "course_level": {zh:"课程等级L1~L5",en:"Level: L1 - L5"},
    "course_more": {zh:"更多课程",en:"More courses"},
    "course_soft":{zh:"设计软件",en:"Design Software"},
    "course_time":{zh:"学习时长",en:"Duration"},
    "course_tool":{zh:"准备工具",en:"Tools"},
    "quick":{zh:"快速玩转3D打印机",en:"Use 3D printer well"},
    "exploreset1":{zh:"断料检测",en:"Filament Detection"},
    "exploreset2":{zh:"连接打印机",en:"Connectivity"},
    "exploreset3":{zh:"断电续打",en:"Power-loss Recovery"},
    "search": {zh:"搜索",en:"Search"},
    "hotValue": {zh:"热门",en:"POPULAR"},
    "new":{zh:"最新",en:"NEW"},
    "premium":{zh:"精选",en:"PREMIUM"},
    "loading":{zh:"拼命加载中",en:'Loading'},
    "explorebanner":{zh:"持续更新的探索课程不断解锁新玩法",en:"Continuously updated videos unlock new gameplay"},
    "learnMore":{zh:"了解更多3D打印玩法",en:"New ways to play 3D printing"},
    "learnMore2":{zh:"了解3D打印的知识",en:"3D printing knowledge"},
    "quesity1":{zh:"为您答疑解惑",en:"How-to guide"},
    "quesity2":{zh:"为您答疑解惑",en:"Use it well"},
    "thingsbanner":{zh:"精心设计",en:"Well designed"},
    "thingsModel":{zh:"万物模型库,",en:"model library."},
    "thingsmodel2":{zh:"无限创意按需智造",en:"Unlimited creativity realized on demand"},
    "tyrto":{zh:"立即体验",en:"Try Now"},
    "Kitbanner":{zh:"更多创意玩法等你解锁",en:"More Creative Gameplay for You to Unlock"},
    "Kitdown":{zh:"立即体验",en:"Try the App now"},
    "kitsecp1":{zh:"3D打印的好伴侣",en:"Best Mate to 3D Printer"},
    "kitsecp2":{zh:"孩子的玩具工厂",en:"Toy Factory for Kids"},
    "kitsecp3":{zh:"无限创意的玩法",en:"Unlimited Ways to Play"},
    "kitsecp4":{zh:"简单易上手",en:"Simple Ways to Create"},
    "kitContenttit1":{zh:"百变赛车套件 X-Racer",en:"Customizable Remote Control Car Kit"},
    "kitContenttit2":{zh:"创意小车套件 X-Auto",en:"Versatile Toy Car Kit"},
    "kitContenttit3":{zh:"童话音乐盒套件 X-Music",en:"Music Box Customization Kit"},
    "kitContenttit4":{zh:"玩具定制套件 X-Fun",en:"Toy Customization Kit"},
    "kitContentp11":{zh:"一套KIT体验多种遥控游戏。",en:"Experience various remote control games with one kit."},
    "kitContentp12":{zh:"和乐高积木匹配，拓展更多玩法。",en:"Compatible with LEGO bricks, expanding gameplay possibilities."},
    "kitContentp21":{zh:"3D打印定制造型，可爱、酷炫，风格多变。",en:"3D print customizable models, cute, cool, and versatile in style."},
    "kitContentp31":{zh:"两种大小不同的内核，可拓展多款造型。",en:"Two different-sized mechanisms for creating various designs."},
    "kitContentp32":{zh:"可定制礼物，也可设计专属自己的八音盒。",en:"Customizable gifts and the option to design your own music box."},
    "kitContentp41":{zh:"配合X-MAKER App中主题设计使用。",en:"Use with Theme in the App."},
    "kitContentp42":{zh:"让3D打印模型更具生命力。",en:"Bring 3D printed models to life."},
    "kidzip":{zh:"下载",en:"DOWNLOAD"},
    "kidziphover1":{zh:"X-Racer 模型资源及组装说明书",en:"X-Racer materials and assembly instructions"},
    "kidziphover2":{zh:"X-Auto 模型资源及组装说明书",en:"X-Auto materials and assembly instructions"},
    "kidziphover3":{zh:"X-Music 模型资源及组装说明书",en:"X-Music materials and assembly instructions"},
    "kidziphover4":{zh:"X-Fun 模型资源及组装说明书",en:"X-Fun  assembly instructions "},
    "headdes1":{zh:"小智3D打印机",en:"A Smart 3D Printer with Gamification 3D Design Apps"},
    "headdes2":{zh:"智能玩具3D打印机",en:"A Mini Smart 3D Printer for Toy Creation"},
    "headdes3":{zh:"百变创意机器人",en:"Unlimited Robot Toy Creation Kit"},
    "headdes4":{zh:"无限玩法创意套件",en:"More Creative Gameplay for You to Unlock"},
    "explore_title1":{zh:"趣味玩法",en:"GAMEPLAY"},
    "explore_courese":{zh:"精品课程",en:"COURSES"},
    "explore_title3":{zh:"教程指南",en:"INSTRUSTIONS"},
    "explore_title4":{zh:"使用手册",en:"MANUAL"},
    "recommend":{zh:"推荐玩法",en:"Recommend"},
    "uploadWork":{zh:"上传作品",en:"Upload"},
    "application":{zh:"我要报名",en:"Application"},
    "CancelRegistration":{zh:"取消报名",en:"Cancel registration"},
    "Success":{zh:"成功",en:"Success"},
    "Fail":{zh:"失败",en:"Fail"},
    "endMatch":{zh:"报名已结束",en:"Registration has ended"},
    "Matchwork":{zh:"作品",en:"works"},
    "MatchWinner":{zh:"获奖者",en:"Winners"},
    "thingKit":{zh:"更多创意游戏玩法等你解锁",en:"More Creative Gameplay for You to Unlock"}
}