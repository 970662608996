import axios from 'axios'
// import Vue from 'vue';
 import store from '../store';
 import i18n from '../i18n';
// import {router} from '../router/index';
// let vm = new Vue();
axios.defaults.crossDomain=true
// const u = navigator.language
// if(u.indexOf("zh") != -1){
//    if(i18n.locale =="en"){
//     //store.state.url ="http://192.168.1.208:8081/aw_ime3d_war/" //本地路径
//     store.state.url ="https://aoseed.ime3d.com"
//    } else{
//     //store.state.url ="http://192.168.1.208:8081/aw_ime3d_war/" //本地路径
//     store.state.url ="https://sv1-cn.aoseedlab.com/"
//    }
// } else {
//   //store.state.url ="http://192.168.1.208:8081/aw_ime3d_war/"
//   store.state.url ="https://aoseed.ime3d.com"
// }
const pathname = window.location.pathname;
store.state.url = pathname!="/ToyLibrary"?"https://sv1-cn.aoseedlab.com" :"https://aoseed.ime3d.com"
const instance = axios.create({
  baseURL:  store.state.url ,
  // baseURL: '/api/',
  // baseURL:'http://192.168.1.208:8081',
  timeout: 30000,
  //withCredentials: true,
  // headers: {
  //   post: {
  //     'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  //   }
   //}
  headers: {
    post: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }
})
const instances = axios.create({
  baseURLs: "",
  timeout: 30000,
  headers: {
    post: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }
})

// 请求拦截
instance.interceptors.request.use(config => {
  // 自定义header，可添加项目token
  // if (store.state.app.token) {
  //   config.headers.token = store.state.app.token;
  //   config.headers.timestamp = new Date().getTime();
  // }
  return config;
}, error => {
  return Promise.reject(error);
})

// 响应拦截
instance.interceptors.response.use(response => {
  // const resCode = response.status;
  // if (resCode === 200) {
  //   return Promise.resolve(response);
  // } else {
  //   return Promise.reject(response);
  // }
  return response;
}, error => {
  // const resCode = error.response.status;
  // switch (resCode) {
  //   case 401:
  //     vm.$Message.error(error.response.data.message);
  //     store.commit('logout', this);
  //     store.commit('clearOpenedSubmenu');
  //     // console.log('token-0', store.state.app.token);
  //     router.replace({
  //       name: 'login'
  //     });
  //     break;
  //   case 404:
  //     vm.$Message.error('网络请求不存在');
  //     break;
  //   case 500:
  //     vm.$Message.error('服务器连接错误');
  //     break;
  //   // 其他状态码错误提示
  //   default:
  //     vm.$Message.error(error.response.data.message);
  // }
  return Promise.reject(error);
})

/*
 *封装get方法
 *@param{String} url [请求地址]
 *@param{Object} params 请求参数
 */
export function Get(url, params) {
  return new Promise((resolve, reject) => {
    instance.get(url, {
      params: params
    }).then((res) => {
      resolve(res.data);
    }).catch((error) => {
      reject(error.data);
    })
  })
}

/*
 *封装post方法
 *@param{String} url 请求地址
 *@param{Object} params 请求参数
 */
export function Post(url, params) {
  return new Promise((resolve, reject) => {
    instance.post(url, params).then((res) => {
      resolve(res.data);
    }).catch((error) => {
      reject(error.data);
    })
  })
}

/**
 *封装put方法
 *@param{String} url 请求地址
 *@param{Object} params 请求参数
 */
export function Put(url, params) {
  return new Promise((resolve, reject) => {
    instance.put(url, params).then((res) => {
      resolve(res.data);
    }).catch((error) => {
      reject(error.data);
    })
  })
}

/**
 *封装patch方法
 *@param{String} url 请求地址
 *@param{Object} params 请求参数
 */
export function Patch(url, params) {
  return new Promise((resolve, reject) => {
    instance.put(url, params).then((res) => {
      resolve(res.data);
    }).catch((error) => {
      reject(error.data);
    })
  })
}

/**
 *封装delete方法
 *@param{String} url [请求地址]
 *@param{Object} params [请求参数]
 */
export function Delete(url, params) {
  return new Promise((resolve, reject) => {
    instance.delete(url, {
      params: params
    }).then((res) => {
      resolve(res.data);
    }).catch((error) => {
      reject(error.data);
    })
  })
}
/*
 *封装get方法
 *@param{String} url [请求地址]
 *@param{Object} params 请求参数
 */
//  export function Post(url, params) {
//   return new Promise((resolve, reject) => {
//     instances.get(url, {
//       params: params
//     }).then((res) => {
//       resolve(res.data);
//     }).catch((error) => {
//       reject(error.data);
//     })
//   })
// }
